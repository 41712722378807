.word-of-day-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.todays-word-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 1086.5px;
}