.navbar-title {
    text-align: center;
    font-size: 50px;
}

.navbar-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    border-bottom: solid 10px rgb(219, 89, 89);
}

.navbar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -20px; 
}
.navbar-button {
    display: flex;
    justify-content: center;
    border-color: transparent;
    background-color: transparent;
    padding: 0px;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
    width: 50px; 
    height: 50px;
}

.daily-diction-logo {
    width: 50px;
    transform: translateY(6px);
}
