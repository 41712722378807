.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1086.5px;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    border-bottom: solid 10px rgb(219, 89, 89);
    padding-top: 20px;
    padding-bottom: 20px;

}

.paragraph {
    margin-left: 40px;
    margin-right: 40px;
    text-indent: 40px;
}