body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-img {
  width: 50px; 
}

.button-img:hover {
  transform: scale(1.1);
}

.loading-wheel {
  width: 75px;
  height: 75px;
  border-radius: 75px;
  border: solid 10px rgb(219, 89, 89);
  border-right: solid 10px transparent;
  animation: infinite ease-in-out 0.8s loading-wheel-spin;
}

@keyframes loading-wheel-spin {
  0% {
    transform: rotate(0deg);
  } 50% {
    transform: rotate(180deg);
  } 100% {
    transform: rotate(360deg);
  }
}

body, html {
  height: 100%;
  width: 100%;
  background-color: rgb(240, 240, 240);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1086.5px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  border-bottom: solid 10px rgb(219, 89, 89);
  padding-top: 20px;
  padding-bottom: 20px;

}

.paragraph {
  margin-left: 40px;
  margin-right: 40px;
  text-indent: 40px;
}