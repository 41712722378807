.footer-content {
    background-color: rgb(240, 240, 240);
    bottom: 0;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
}