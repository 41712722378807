.today-word-content-container {
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    border-bottom: solid 10px rgb(219, 89, 89);
}

.today-word-share-content {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.today-word-content {
    background-color: white;
    display: flex;
    flex-direction: row;
}

.today-word-text-content-container {
    width: 50%;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.today-word-text-content {
    width: 90%;
}

.today-word-img-content-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.today-word-img-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.today-word-img {
    width: 100%;
}


.word-button {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-color: transparent;
    border-color: transparent;
    padding: 0px;
}

.share-message {
    display: flex;
    color: grey;
    font-style: italic;
    align-items: center;
    height: 54px;
}



/*
background-color: transparent;
border-color: transparent;
*/